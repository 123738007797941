<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <h1 class="titlePage titlePage_textLeft">Forgot password</h1>
        <Form action="" data-vv-scope="form-2" class="form" @submit="resetPassword">
          <label class="form__label">
            <span class="form__labelTitle">Current Password</span>
            <Field type="password" class="form__input"
                   name="current password"
                   placeholder="Current Password"
                   :rules="{required: true, min: 4}"
                   v-model="password"
                   autocomplete="off"
            />
             <ErrorMessage class="form__error"  name="current password" />
          </label>
          <label class="form__label">
            <span class="form__labelTitle">New Password</span>
            <Field type="password" class="form__input"
                   id="new_password"
                   name="new_password"
                   placeholder="New Password"
                   :rules="{required: true, min: 4}"
                   v-model="new_password"
                   ref="new_password"
                   autocomplete="off"
            />
             <ErrorMessage class="form__error"  name="new password" />
          </label>
          <label class="form__label">
            <span class="form__labelTitle">Confirmed Password</span>
            <Field type="password" class="form__input"
                   name="confirm_password"
                   placeholder="Confirmed Password"
                   :rules="'required|confirmed:@new_password'"
                   label="password"
                   autocomplete="off"
            />
             <ErrorMessage class="form__error"  name="confirm_password" />
          </label>
          <button class="btn_default btn" type="submit"><svg-icon name="solid/unlock-alt" />Change Password</button>
        </Form>
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    name: "ResetPassword",

    data() {
      return {
        password: '',
        new_password: '',
        email: ''
      }
    },

    methods: {
      resetPassword() {
        this.getEmail();
       /* this.$validator.validate().then(valid => {
          if (valid) {*/
            this.$store.dispatch('resetPassword/sendReset', {
              email: this.email,
              password: this.password.trim(),
              new_password: this.new_password.trim(),
            });
          /*}
        });*/
      },

      getEmail() {
        this.email = this.$route.params.email;
      },

    },

    computed: {}

  }
</script>

<style scoped lang="scss">

  .form {
    margin-bottom: 2rem;
  }

</style>
